<template>
  <div id="app">
    <van-popup
      v-model="key.visible"
      v-if="key.visible"
      :style="{
        height: '100%',
        width: '100%',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
      }"
    >
      <van-form @submit="submitKeyValue">
        <van-field
          v-model="key.value"
          placeholder="主密钥"
          :rules="[{ required: true, message: '' }]"
          clearable
          input-align="center"
        />

        <div style="margin: 16px;">
          <van-button :disabled="!listLoadIsFinish" round type="info" native-type="submit"
            >确定</van-button
          >
        </div>
      </van-form>
    </van-popup>

    <van-row class="footer">
      <van-col span="12">
        <van-button block type="info" @click="onAdd" icon="plus">添加</van-button></van-col
      >
      <van-col span="12"> <van-switch v-model="eye"></van-switch></van-col>
    </van-row>

    <van-popup v-model="show" round position="bottom" :style="{ height: '50%' }">
      <van-form @submit="onSubmit" style="margin: 16px">
        <h2>{{ form.title }}</h2>
        <van-field
          v-model="form.n"
          name="n"
          label="名称"
          placeholder="名称"
          :rules="[{ required: true, message: '请填写' }]"
          clearable
        />
        <van-field
          v-model="form.p"
          name="p"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写' }]"
          clearable
        />

        <van-field
          v-model="form.b"
          name="b"
          label="备注"
          placeholder="备注"
          :rules="[{ required: false, message: '请填写' }]"
          clearable
        />

        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>

    <van-cell-group style="margin-top:8px">
      <!-- <van-cell v-for="(e, i) in list" :key="i" :title="e.n" :value="e.p" /> -->

      <van-swipe-cell
        v-for="(e, i) in decryptList"
        :key="i"
        @open="open($event, e, i)"
        ref="swipeCell"
      >
        <template #left>
          <van-button square type="danger" text="删除" @click="onDelete(e)" />
        </template>
        <van-cell class="my-cell" :value="eye ? e.dp : e.p">
          <template #title>
            <span class="custom-title">{{ e.n }}</span>
          </template>

          <template #label>
            <span class="custom-label">{{ e.b }}</span>
          </template>

          <template #right-icon>
            <van-icon
              name="font"
              class="icon"
              v-clipboard:copy="e.dp"
              v-clipboard:success="$onCopy"
              v-clipboard:error="$onError"
            />
          </template>
        </van-cell>
        <template #right>
          <van-button square type="primary" text="修改" @click="onEdit(e)" />
        </template>
      </van-swipe-cell>
    </van-cell-group>
  </div>
</template>

<script>
const CryptoJS = require('crypto-js');
const qs = require('qs');

/**** set token ******** */
var NSQ_TOKEN = GetRequest('token');
if (NSQ_TOKEN) {
  localStorage.setItem('nsq-token', NSQ_TOKEN);
}

const HEADERS = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Authorization: 'Bearer ' + localStorage.getItem('nsq-token'),
};

var API = '';
const HTTPS_TEXT = location.protocol + '//';
// if (process.env.NODE_ENV == 'development') {
//   API = HTTPS_TEXT + 'pw.nanshanqiao.com';
// }

const CALL_BACK = HTTPS_TEXT + 'account.nanshanqiao.com/index.html?callback=' + location.href;

const formatData = function(data) {
  if (process.env.NODE_ENV == 'development') {
    data = { ...data, uid_is_1: 5 };
  }
  return qs.stringify(data);
};

/**
 * GetRequest
 * @returns json
 */
function GetRequest(key) {
  if (typeof URLSearchParams === 'function') {
    return new URLSearchParams(location.search).get(key);
  }
  var url = location.search; //获取url中"?"符后的字串
  var json = {};
  if (url.indexOf('?') != -1) {
    var str = url.substr(1);
    str = str.split('&');
    for (var i = 0; i < str.length; i++) {
      json[str[i].split('=')[0]] = decodeURIComponent(str[i].split('=')[1]);
    }
  }
  return json[key];
}
export default {
  name: 'App',
  components: {},
  data() {
    return {
      // 列表加载完成
      listLoadIsFinish: false,
      // 主密钥
      key: {
        visible: true,
        value: '',
      },
      // 新建/修改的弹窗
      show: false,
      form: {
        title: null,
        id: null,
        p: null,
        n: null,
        b: null,
      },
      // 原数据
      list: [],
      // 显示解密数据
      eye: false,
    };
  },
  computed: {
    // 解密后的数据
    decryptList() {
      return this.list.map(e => {
        const bytes = CryptoJS.AES.decrypt(e.p, this.key.value || '');
        let DP = '';
        try {
          DP = bytes.toString(CryptoJS.enc.Utf8);
        } catch (error) {
          console.log(error);
        }
        return {
          ...e,
          dp: DP,
          p: e.p.slice(0, 20) + '...',
        };
      });
    },
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    // 滑动
    open(event, e, i) {
      console.log(e);
      if (!e.dp) {
        this.$toast('open error, 主密钥错误');
        this.$refs.swipeCell[i].close();
      }
    },
    // 删除
    onDelete(e) {
      this.$dialog
        .confirm({
          title: `你确定要删除 ${e.n} 吗`,
          message: `不可恢复`,
        })
        .then(() => {
          fetch(API + '/api/getInfo', {
            method: 'POST',
            headers: HEADERS,
            body: formatData({ info: 'delete', id: e.i }),
          })
            .then(res => res.json())
            .then(json => {
              if (json.tip == 1) {
                this.getInfo();
              } else {
                this.$toast.fail(json.d);
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 新建
    onAdd() {
      this.show = true;
      this.form.title = '新增密码记录';
      this.form.id = null;
      this.form.p = null;
      this.form.n = null;
      this.form.b = null;
    },
    // 修改
    onEdit(row) {
      this.show = true;
      this.form.title = '修改密码记录';
      this.form.id = row.i;
      this.form.p = row.p;
      this.form.n = row.n;
      this.form.b = row.b;
    },
    // 提交（新建 或 修改）
    onSubmit(values) {
      const d = {
        info: 'insert',
        n: values.n,
        p: this.Encrypt(values.p),
        b: values.b,
      };

      if (this.form.id) {
        d.info = 'update';
        d.id = this.form.id;
      }

      fetch(API + '/api/getInfo', {
        method: 'POST',
        headers: HEADERS,
        body: formatData(d),
      })
        .then(res => res.json())
        .then(json => {
          if (json.tip == 1) {
            this.show = false;
            this.getInfo();
          } else {
            this.$toast.fail(json.d);
          }
        });
    },
    // 获取LIST
    getInfo() {
      fetch(API + '/api/getInfo', {
        method: 'POST',
        headers: HEADERS,
        body: formatData({ info: 'select' }),
      })
        .then(res => res.json())
        .then(json => {
          if (json.tip == 0) {
            if (process.env.NODE_ENV != 'development') {
              window.location = CALL_BACK;
            } else {
              console.log(CALL_BACK);
            }
          } else {
            this.list = json.d;
            this.listLoadIsFinish = true;
          }
        });
    },
    // 检测主密钥
    submitKeyValue() {
      if (this.decryptList.length > 0) {
        if (!this.decryptList[0].dp) {
          this.$toast('主密钥错误');
          return;
        }
      } else {
        this.$dialog
          .alert({
            title: this.key.value,
            message: '请记住您的主密钥，此密钥不会保存在网站上！无法找回！',
          })
          .then(() => {
            // on close
          });
      }
      this.key.visible = false;
    },
    // 加密
    Encrypt(msg) {
      if (!this.key.value) {
        location.reload();
      }
      const ciphertext = CryptoJS.AES.encrypt(msg, this.key.value || '').toString();
      return ciphertext;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-bottom: 45px;
}

.van-icon.icon {
  font-size: 20px;
  line-height: inherit;
  margin-left: 8px;
  color: #1989fa;
  /* // #07c160; */
}
.my-cell .van-cell__value {
  min-width: 70%;
}
.my-cell .van-cell__value span {
  display: inline-block;
  text-align: left;
  word-break: break-all;
}
.van-swipe-cell__right > .van-button,
.van-swipe-cell__left > .van-button {
  height: 100%;
}

.footer {
  z-index: 1;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  background: #fff;
}
.footer .van-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-label {
  position: absolute;
  margin-top: -10px;
}
</style>
