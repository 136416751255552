import Vue from 'vue';
import App from './App.vue';
Vue.config.productionTip = false;

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

Vue.prototype.$onCopy = () => {
  Vue.prototype.$toast.success('复制成功');
};
Vue.prototype.$onError = () => {
  Vue.prototype.$toast.error('复制失败');
};

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

new Vue({
  render: (h) => h(App),
}).$mount('#app');
